import React, { useEffect, useState } from 'react';
// import './App.css';
import { AddBox,  DragIndicator, Publish, DeleteForever } from '@material-ui/icons';
import {
  Button, Dialog, DialogContent, DialogTitle, Typography, FormControl, IconButton, InputLabel,
  MenuItem, Paper, Select, Stepper, Step, StepLabel, StepContent,  TextField, Table, CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DialogActions } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import Papa from 'papaparse';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { isArray } from "lodash";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    justifyContent: "center",
    background: "#f4f4f4",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  sortableList: {
    flex: 1,
    background: "#ffffff",
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "0 13px 46px rgba(0, 0, 0, 0.1)",
    alignItems: "center",
    justifyContent: "center",
  },

  inputField: {
    padding: "10px",
    margin: "8px 0",
    border: "1px solid #ccc",
    borderRadius: "14px",
    fontSize: "16px",
    width: "100%",
    boxShadow: "0 3px 16px rgba(0, 0, 0, 0.1)",
  },

  addButton: {
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "14px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "16px",
    width: "100%",
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      backgroundColor: "#0056b3"
    }

  },

  sortableListItem: {
    flex: 1, /* isto é para a nav ocupar a largura toda menos a logo */
    justifyContent: "center", /* alinha horizontalmente */
    alignItems: "center", /* alinha verticalmente */
    listStyle: "none",
    cursor: "move",
    background: "lightGrey",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
  },

  itemDetails: {
    display: "flex",
    justifyContent: "left", /* alinha horizontalmente */
    alignItems: "left",
  },

  itemDetailsSpan: {
    fontSize: "1.2rem",
    fonteWight: "500",
    color: "#333",
  },

  itemI: {
    color: "#474747",
    fontSize: "1.13rem",
  },

  itemDragging: {
    opacity: "0.6",
    color: theme.palette.primary.main
  },
  itemDraggingDelete: {
    opacity: "0.6",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },

  dragging: {
    opacity: "0.8",
    transform: "scale(1.02)",
    background: "#f0f0f0",
  },

  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    minWidth: 300
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  circleLoading: {

    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

}));


const ContactImportModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles();
  const { whatsApps } = useWhatsApps();
  const [items, setItems] = useState([]);
  const [itemsNew, setItemsNew] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const [itemsNewSelected, setItemsNewSelected] = useState();
  const [filename, setFilename] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [preview, setPreview] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [tags, setTags] = useState({ id: "", name: "" });
  const [tagsData, setTagsData] = useState({ id: "", name: "" });
  const [whatsAppsConnected, setWhatsAppsConnected] = useState([]);
  const [loading, setLoading] = useState(false);
  const steps = getSteps();

  useEffect(() => {
    try {
      (async () => {
        const f = whatsApps.filter(function (e) { return e.status === 'CONNECTED' && e.isDefault === true; })
        setWhatsAppsConnected(f);
        if (whatsApps.length === 0) return;
      })()
    } catch (err) {

    }
  }, [open]);

  useEffect(() => {
    setItems([
      { id: 1, name: 'Número', fieldName: 'number' },
    ]);

    setItemsNew([
      { id: 0, name: 'Nome', fieldName: 'name' },
      { name: 'E-Mail', fieldName: 'email' },
      { name: 'Data Nascimento', fieldName: 'birthday' },
      { name: 'Código', fieldName: 'cod' },
    ]);
  }, [open])

  useEffect(() => {
    try {
      (async () => {

        const { data } = await api.get('/tags/list');
        let customList = data.map((c) => ({ id: c.id, name: c.name }));
        if (isArray(customList)) {
          setTags([{ id: "", name: "" }, ...customList]);
        }

      })()
    } catch (err) {
      toastError(err);
    }
  }, [open]);


  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    if (!draggingItem) return;
    const currentIndex = items.indexOf(draggingItem);
    const targetIndex = items.indexOf(targetItem);
    if (currentIndex !== -1 && targetIndex !== -1) {
      const newItems = [...items];
      newItems.splice(currentIndex, 1);
      newItems.splice(targetIndex, 0, draggingItem);
      setItems(newItems);

    }
  };

  const addNewItem = () => {
    console.log("itemsNewSelected", itemsNewSelected)
    if (!itemsNewSelected) {
      alert("Selecione um item");
      return;
    }
    const itemNewSelected = itemsNew?.filter(function (e) { return e.fieldName === itemsNewSelected; })
    const newItemId = Math.max(...items?.map((item) => (item.id))) + 1;
    const newItem = { id: newItemId, name: itemNewSelected[0].name, fieldName: itemNewSelected[0].fieldName };
    setItems([...items, newItem]);
    setItemsNew(itemsNew?.filter(function (e) { return e.fieldName !== itemsNewSelected; }))
    setItemsNewSelected("")
  };

  const handleDeleteItem = (item) => {
    const itemDeleted = items?.filter(function (e) { return e.fieldName !== item.fieldName; })
    setItems(itemDeleted)
    setItemsNew([...itemsNew, { name: item.name, fieldName: item.fieldName }]);
    setItemsNewSelected("")
  };

  const handleClose = () => {
    onClose();
    setItemsNewSelected("")
  };

  const handleFilePreview = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);
    setFileUpload(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      Papa.parse(csv, {
        // header: true,
        complete: (results) => {
          const resultsData = results.data.filter(function (e) { return e[0] !== ''; })
          setPreview(resultsData);
        },
      });
    };
    reader.readAsText(file);

  };

  function getSteps() {
    return ['Selecione um arquivo CSV', 'Associe ou crie uma Etiqueta/Tag', 'Selecione e ordene os campos'];
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setLoading(true);
      handleFileUpload()
      setTimeout(async () => {
        
        setFilename('');
        setFileUpload('');
        setPreview('');
        setTagsData('')
        console.log("time", preview.length);
        setLoading(false);
      }, 2 * preview.length)

    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setFilename('');
    setFileUpload('');
    setPreview('');
    setTagsData('')
    setActiveStep(0);
    onClose();

  };


  const handleFileUpload = async (e) => {
    if (preview.length < 1) return;

    try {

      await api.post(`/contacts/importContacts`, { preview, items, tags: tagsData });

    } catch (err) {
      toastError(err);
    }
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">

        {whatsAppsConnected.length === 0
          ?
          <>
            <DialogTitle id="form-dialog-title">
              <Alert severity="info">
                <AlertTitle>INFO</AlertTitle>
                {i18n.t("campaignsModal.title.noConnected")}
              </Alert>
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                {i18n.t("global.buttons.close")}
              </Button>
            </DialogActions>
          </>
          :
          <>
            <DialogTitle id="form-dialog-title">
              Importar contatos
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                {i18n.t("global.buttons.close")}
              </Button>

            </DialogTitle>
            
            <DialogContent dividers>

              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {index === 0 &&
                        <DialogContent dividers>

                          <div className={classes.paper}>
                            <Button
                              component="label"
                              variant="outlined"
                              startIcon={<Publish />}
                            >
                              Selecionar arquivo
                              <input type="file" accept=".csv" hidden onChange={handleFilePreview} />
                            </Button>
                          </div>
                          <div className={classes.paper}>{filename}</div>

                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={preview.length > 0 ? false : true}
                                onClick={handleNext}
                                className={classes.button}
                              >
                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                              </Button>
                            </div>
                          </div>
                        </DialogContent>


                      }
                      {index === 1 &&
                        <DialogContent dividers>

                          <div className={classes.paper}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                            >
                              <Autocomplete
                                freeSolo
                                fullWidth
                                options={tags}
                                onChange={(e, tag) => {
                                  const tags = tag ? tag : '';
                                  setTagsData({ id: tags.id, name: tags.name });
                                }}
                                onInputChange={async (event, value, reason) => {

                                  if (reason === 'input') {
                                    setTagsData({ name: value });
                                  }
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => {
                                  return value.id === option.id
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={i18n.t("campaignsRepostsSaveListModal.tagName")} />}
                              />
                            </FormControl>

                          </div>


                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                              >
                                Voltar
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                disabled={tags ? false : true}
                                onClick={handleNext}
                                className={classes.button}
                              >
                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                              </Button>

                            </div>

                          </div>
                        </DialogContent>

                      }
                      {index === 2 && preview.length > 0 && (
                        <>
                          <div className={classes.paper}>
                            <FormControl className={classes.formControl} style={{ flexDirection: 'row' }}>
                              <InputLabel>Selecione um campo para adicionar</InputLabel>
                              <Select
                                id="newSelected"
                                name="newSelected"
                                className={classes.input}
                                onChange={(e) => setItemsNewSelected(e.target.value)}
                                label={i18n.t("ticketsList.acceptModal.queue")}
                              >
                                {itemsNew?.map((q, index) => (
                                  <MenuItem key={index} value={q.fieldName}>{q.name}</MenuItem>
                                ))}
                              </Select>

                              <IconButton onClick={() => addNewItem()} color="primary" className={classes.iconButton} aria-label="directions">
                                <AddBox />
                              </IconButton>
                            </FormControl>
                          </div>
                          {/* <div className={classes.paper}>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            control={
                              <Switch
                                as={Switch}
                                color="primary"
                                name="status"
                                checked={heads}
                                onClick={(e) => setHeads(!heads)}
                              />
                            }
                            label="O arquivo contém cabeçalho"
                          />
                        </FormControl>
                      </div> */}
                          <Paper square elevation={0} className={classes.resetContainer}>
                            <span>Clique e arraste para ordernar os campos conforme o arquivo</span>
                            <Table border='1'>
                              <thead>
                                <tr >
                                  {items?.map((item, index) => (
                                    <th
                                      key={(index)}
                                      className={`${classes.sortableListItem} ${item.id === draggingItem?.id ? classes.dragging : ''}`}
                                      draggable="true"
                                      onDragStart={(e) => handleDragStart(e, item)}
                                      onDragEnd={handleDragEnd}
                                      onDragOver={handleDragOver}
                                      onDrop={(e) => handleDrop(e, item)}

                                    >
                                      <div className={classes.itemDetails} >
                                        <DragIndicator className={`${classes.itemDragging}`} />
                                        <span className={classes.itemDetailsSpan} >{item.name}</span>
                                        {item.fieldName !== "number" &&
                                          <DeleteForever className={`${classes.itemDraggingDelete}`} onClick={(e) => handleDeleteItem(item)} />
                                        }
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>


                                {
                                  (preview?.map((linha, indice) => indice < 3 && (
                                    // preview.map((linha, indice) => (
                                    <tr key={indice}>
                                      {Object.values(linha).map((valor) => (
                                        <td key={valor}>{valor}</td>
                                      ))}
                                    </tr>
                                  )))
                                }

                                {/* {heads &&
                              // (preview.map((linha, indice) => indice < 4 && ((
                              (preview.map((linha, indice) => ((
                                (indice > 0 &&
                                  <tr key={indice}>
                                    {Object.values(linha).map((valor) => (
                                      <td key={valor}>{indice} | {valor}</td>
                                    ))}
                                  </tr>
                                )
                              ))))
                            } */}
                                {preview.length > 3 && <tr key={4}>mostrando 3 de {preview.length} registro(s)</tr>}
                              </tbody>
                            </Table>
                          </Paper>
                        </>
                      )}
                      {activeStep > 1 &&
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Voltar
                            </Button>
                            {activeStep === steps.length - 1 &&
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                              >
                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                              </Button>
                            }
                          </div>
                        </div>
                      }
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {loading ? (
                <div>
                  <CircularProgress className={classes.circleLoading} />
                </div>
              ) : (
                activeStep === steps.length && (
                  <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>Todas as etapas foram concluídas</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleReset}
                      className={classes.button}>
                      Fechar
                    </Button>
                  </Paper>
                )
              )}
            </DialogContent>


          </>
        }

      </Dialog>
    </div>
  );
}

export default ContactImportModal;